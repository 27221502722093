import Vue from "vue"
import Vuex from 'vuex'

Vue.use(Vuex)

import authModule from "./modules/auth-module";
import mailModule from "./modules/mail-module";
import globalModule from "./modules/global-module";

export default new Vuex.Store({
    // actions: {
    //     INIT() { },
    //     USER_REQUEST: () => {
    //         console.log('fetch user details')
    //     }
    // },
    modules: { auth: authModule, mail: mailModule, global: globalModule }
});
