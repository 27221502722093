export default {
    path: "/",
    component: () => import('../../views/mail/Dashboard.vue'),
    redirect: "/mail/inbox",
    children: [
        {
            path: "/mail/inbox",
            component: () => import('../../views/mail/fields/Inbox.vue'),
            name: 'inbox'
        },
        {
            path: "/mail/inbox/:id",
            component: () => import('../../views/mail/fields/Inbox.vue'),
            name: 'inbox'
        },
        {
            path: "/mail/sent",
            component: () => import('../../views/mail/fields/Sent.vue'),
            name: 'sent'
        },
        {
            path: "/mail/sent/:id",
            component: () => import('../../views/mail/fields/Sent.vue'),
            name: 'sent'
        },
        // {
        //     path: `/mail/:dashboard`,
        //     component: () => import('../../views/mail/MailContainer.vue'),
        // },
        {
            path: "/compose",
            component: () => import('../../views/mail/components/compose.vue'),
        },
    ],
    meta: {
        requiresAuth: true
    }
};
