import Vue from 'vue'
import VueRouter from 'vue-router'

//routes
import authRoutes from "./routes/authRoutes";
import dashboardRoutes from "./routes/dashboardRoutes";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    authRoutes,
    dashboardRoutes,
    {
      path: "*",
      redirect: "/auth"
    }
  ]
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err
  });
}

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'ShairMail';

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const noUserAvailable = to.matched.some(record => record.meta.noUserAvailable);

  try {
    const status = localStorage.getItem('auth-key');

    if (requiresAuth && !status) {
      next("/auth/login");
    }
    else if (noUserAvailable && !!status) {
      next("/");
    }
    else next();

  } catch (error) {
    // display forceful logout message call logout()
    console.log('ROUTER_ERROR', error)

    next("/auth/login");
  }
})

export default router;