<template>
  <v-app id="app">
    <alerts></alerts>
    <router-view />
  </v-app>
</template>
<script>
import alerts from "./views/alerts.vue";

export default {
  components: { alerts },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Crimson+Text&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
// font-family: "Niconne", cursive;
// font-family: 'Crimson Text', serif;
// }

#app {
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
