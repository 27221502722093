import axios from "@/plugins/axios.js";
import ledgermail_v2 from "@/plugins/ledgermail-jslib";
import coreApi from "@/plugins/core-api.js";
// import router from "@/router/index"

const state = {
    token: localStorage.getItem('auth-key') || '',
    status: 'pending',
    user: JSON.parse(localStorage.getItem('user-details')) || {}
}

const mutations = {
    AUTH_REQUEST: (state) => {
        state.status = 'loading'
    },
    AUTH_SUCCESS: (state, { xKey, user }) => {
        state.status = 'success'
        state.token = xKey
        state.user = user
    },
    AUTH_ERROR: (state) => {
        state.status = 'error'
        state.token = ''
    },
    AUTH_PENDING: (state) => {
        state.status = 'pending'
    }
};

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    extendedPublicKey: state => state.token,
    userInfo: state => state.user,
    mnemonic: state => state.user.mnemonic
};

const actions = {
    AUTH_INIT: async ({ getters, dispatch }) => {
        if (getters.extendedPublicKey.length > 0)
            axios.getUserDetails(getters.extendedPublicKey).then(({ data }) => {
                if (data.error) dispatch('AUTH_LOGOUT');
            });
        else dispatch('AUTH_LOGOUT');
    },
    GET_PUBIC_KEYS: ({ commit }, emailIds) => {
        commit('DUMMY', true);
        return axios.getPublicKeys(emailIds);
    },
    NEW_USER: ({ commit }, email) => {
        commit('AUTH_REQUEST')

        return new Promise((resolve, reject) => {
            axios.sendToken(email).then(() => {
                commit('AUTH_PENDING')
                resolve()
            }).catch((error) => {
                console.log('[Create user] ERROR: ', error)
                commit('AUTH_PENDING')
                reject("Error 404: Please check your connection and try again later!")
            });
        })
    },
    VERIFY_TOKEN: ({ commit }, token) => {
        commit('AUTH_REQUEST')

        return new Promise((resolve, reject) => {
            axios.verifyToken(token).then(({ data }) => {
                commit('AUTH_PENDING')
                resolve(data)
            }).catch((error) => {
                console.log('[Create user] ERROR: ', error)
                commit('AUTH_PENDING')
                reject("Error 404: Please check your connection and try again later!")
            });

        })
    },
    CREATE_USER: async ({ commit }, { firstName, lastName, emailId, token, mnemonic }) => {
        commit('AUTH_REQUEST')

        const publicKey = await ledgermail_v2.getHexPublicKeyFromPhrase(mnemonic)
        const extPublicKey = await ledgermail_v2.getXPublicKey(await ledgermail_v2.getHexFormMnemonic(mnemonic));
        const privateKey = await ledgermail_v2.getPrivateKeyFromPhrase(mnemonic);

        return new Promise((resolve, reject) => {
            try {
                axios.getSignature("CREATE_USER", { firstName, lastName, publicKey, extPublicKey }, token)
                    .then(({ data }) => {
                        coreApi.createUser(privateKey, { firstName, lastName, emailId, publicKey, extPublicKey }, data.signature)
                            .then((result) => {
                                commit('AUTH_PENDING')

                                if (result.success) resolve();
                                else {
                                    console.log('[Create user] ERROR: ', result.response)
                                    reject(result.message)
                                }
                            })
                    }).catch((error) => {
                        console.log('[Create user] ERROR: ', error)
                        commit('AUTH_PENDING')
                        reject("Error 404: Please check your connection and try again later!")
                    });

            } catch (error) {
                commit('AUTH_PENDING')
                reject(error)
            }
        })
    },
    AUTH_LOGIN: async ({ commit, dispatch }, { mnemonic }) => {
        commit('AUTH_REQUEST')
        const extPublicKey = await ledgermail_v2.getXPublicKey(await ledgermail_v2.getHexFormMnemonic(mnemonic));

        return new Promise((resolve, reject) => {
            try {
                axios.getUserDetails(extPublicKey).then(({ data }) => {
                    if (data.error) {
                        dispatch('AUTH_LOGOUT')
                        reject(data.error)
                    }
                    else {
                        commit('AUTH_SUCCESS', { xKey: extPublicKey, user: { ...data.data, mnemonic } })
                        localStorage.setItem('auth-key', extPublicKey)
                        localStorage.setItem('user-details', JSON.stringify({ ...data.data, mnemonic }))
                        resolve();
                    }
                });
            } catch (error) {
                dispatch('AUTH_LOGOUT')
                reject(error)
            }
        })
    },
    AUTH_LOGOUT: ({ commit }) => {
        return new Promise((resolve) => {
            commit('AUTH_PENDING')
            commit('RESET_MAIL_STAGE')

            localStorage.removeItem('user-details')
            localStorage.removeItem('auth-key')
            // router.push("/auth")
            resolve()
        })
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};