import Axios from "axios";

const axios = Axios.create({ baseURL: `${process.env.VUE_APP_BASE_URL_SERVER}/api` });

export default {
    async sendToken(email) {
        return (await axios.get(`/auth/verificationMail?email=${email}`));
    },
    async verifyToken(token) {
        return (await axios.get(`/auth/verifyToken?token=${token}`));
    },
    async getSignature(type, body, token) {
        return (await axios.get(`/auth/signature?type=${type}&body=${JSON.stringify(body)}&token=${token}`));
    },
    async getUserDetails(extPublicKey) {
        return (await axios.get(`/auth/login?extPublicKey=${extPublicKey}`));
    },

    async getPublicKeys(emailIds) {
        return (await axios.post(`/auth/publicKeys`, emailIds));
    },


    async getInboxMails(emailId) {
        return (await axios.get(`/ledgermail/inbox?email=${emailId}`));
    },
    async getSentMails(emailId) {
        return (await axios.get(`/ledgermail/sent?email=${emailId}`));
    },
}