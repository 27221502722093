const request = require("request");
const ledgermail_v2 = require("./ledgermail-jslib")
const ObjectID = require('bson').ObjectID;


async function verifyAndCreateUser(secret, txBody, orgSignature) {
    const signature = await ledgermail_v2.verifyTransaction(txBody, secret)
    const reqData = { type: "CREATE_USER", body: txBody, signature, orgSignature, now: Date.now() }

    const baseRequest = Buffer.from(JSON.stringify(reqData)).toString("base64")
    const headers = { 'Content-Type': 'text/plain', 'Accept': 'application/json-rpc' }
    const options = {
        url: process.env.VUE_APP_BASE_URL_CORE, method: "POST", headers, json: true,
        body: { jsonrpc: "2.0", id: "ledgermail-user", method: "broadcast_tx_commit", params: { tx: baseRequest } }
    }
    return new Promise((resolve, reject) => { request(options, (error, response, body) => { if (error) reject(error); resolve({ body, response }); }) })
}

async function verifyAndCreateMail(secret, txBody) {
    const signature = await ledgermail_v2.verifyTransaction(txBody, secret)
    const reqData = { type: "CREATE_MAIL", body: txBody, signature: signature, now: Date.now() };

    const baseReqTxData = Buffer.from(JSON.stringify(reqData)).toString("base64");
    let headers = { 'Content-Type': 'text/plain', 'Accept': 'application/json-rpc' }
    let options = {
        url: `${process.env.VUE_APP_BASE_URL_CORE}`, method: "POST", headers: headers, json: true,
        body: { jsonrpc: "2.0", id: "ledgermail-tx", method: "broadcast_tx_commit", params: { tx: baseReqTxData } }
    }
    return new Promise((resolve, reject) => { request(options, (error, response, body) => { if (error) reject(error); resolve({ body, response }); }) })
}



export default {
    createUser(privateKey, { firstName, lastName, emailId, publicKey, extPublicKey }, orgSignature) {
        const data = { emailId, firstName, lastName, publicKey, extPublicKey };
        const base64Data = Buffer.from(JSON.stringify(data)).toString("base64");

        return verifyAndCreateUser(privateKey, base64Data, orgSignature)
            .then((res) => {
                return getProcessedBroadcastResponse(res.body);
            }).catch((error) => {
                console.log('createMail Error', error)
                return { success: false, message: 'Error #501, Try again after some time!' }
            })
    },

    async createMail(privateKey, { sender, receiver, subject, body, attachments, CC, secrets }) {
        const lastUpdateTime = new Date(Date.now()).toISOString()
        const data = { sender, receiver, subject, body, attachments, CC, secrets, parentId: '', mailtype: 'newMail', lastUpdate: lastUpdateTime, uniqueid: new ObjectID().toString() };
        const base64Data = Buffer.from(JSON.stringify(data)).toString("base64");

        return await verifyAndCreateMail(privateKey, base64Data)
            .then((res) => {
                return getProcessedBroadcastResponse(res.body);
            }).catch((err) => {
                console.log('Mail Error', err)
                return { success: false, message: 'Error #501, Try again after some time!' }
            })
    },

    async replyMail(privateKey, { sender, receiver, subject, body, attachments, CC, secrets, parentId }) {
        const lastUpdateTime = new Date(Date.now()).toISOString()
        const data = { sender, receiver, subject, body, attachments, CC, secrets, parentId, mailtype: 'replyMail', lastUpdate: lastUpdateTime, uniqueid: new ObjectID().toString() };
        const base64Data = Buffer.from(JSON.stringify(data)).toString("base64");

        return await verifyAndCreateMail(privateKey, base64Data)
            .then((res) => {
                return getProcessedBroadcastResponse(res.body);
            }).catch((err) => {
                console.log('Mail Error', err)
                return { success: false, message: 'Error #501, Try again after some time!' }
            })
    },

    async forwardMail(privateKey, { sender, receiver, subject, body, attachments, CC, secrets, previousAttachments, previousMails }) {
        const lastUpdateTime = new Date(Date.now()).toISOString()
        const data = { sender, receiver, subject, body, attachments, CC, secrets, parentId: '', mailtype: 'forwardMail', previousAttachments, previousMails, lastUpdate: lastUpdateTime, uniqueid: new ObjectID().toString() };
        const base64Data = Buffer.from(JSON.stringify(data)).toString("base64");
        console.log('data:', data)

        return await verifyAndCreateMail(privateKey, base64Data)
            .then((res) => {
                return getProcessedBroadcastResponse(res.body);
            }).catch((err) => {
                console.log('Mail Error', err)
                return { success: false, message: 'Error #501, Try again after some time!' }
            })
    },
}

// Helper Function
async function getProcessedBroadcastResponse(response) {
    const res = response.result;
    if (response.error) { console.log(response); return { success: false, message: response.error.data } }
    else if (res.check_tx.code === 0 && res.deliver_tx.code === 0) return { success: true, response: res };
    else return { success: false, response: res, message: res.check_tx.info || res.deliver_tx.info };
}