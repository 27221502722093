export default {
    path: "/auth",
    component: () => import('../../views/authentication/AuthContainer.vue'),
    redirect: "/auth/signup",
    children: [
        {
            path: "/auth/login",
            component: () => import('../../views/authentication/Login.vue'),
            meta: {
                title: "Sign in to ShairMail"
            }
        },
        {
            path: "/auth/signup",
            component: () => import('../../views/authentication/Signup.vue'),
            meta: {
                title: "Join to ShairMail"
            }
        },
        {
            path: "/auth/email-verification",
            component: () => import('../../views/authentication/CreateUser.vue'),
            meta: {
                title: "Create Account"
            }
        },
    ],
    meta: {
        noUserAvailable: true
    }
};
