import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import ledgermail from './plugins/ledgermail'
import ledgermailJsLib from './plugins/ledgermail-jslib'


Vue.config.productionTip = false;
Vue.prototype.$ledgermail = ledgermail;
Vue.prototype.$ledgermail_v2 = ledgermailJsLib;

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
