import axios from "@/plugins/axios.js";
import coreApi from "@/plugins/core-api.js";
const CryptoJS = require("crypto-js");
import ledgermail_v2 from "@/plugins/ledgermail-jslib";

const state = {
    mailStatus: 'pending',
    localMails: JSON.parse(localStorage.getItem('all-mails')) || [],
    inboxMails: [],
    sentMails: [],
    dummy: true,
}

const mutations = {
    INIT_MAIL_REQUEST: (state) => {
        state.mailStatus = "loading"
    },
    INIT_MAIL_SUCCESS: (state, { inboxMails, sentMails }) => {
        state.mailStatus = "success"
        state.inboxMails = inboxMails
        state.sentMails = sentMails
    },
    INIT_MAIL_ERROR: (state) => {
        state.mailStatus = "error"
    },
    RESET_MAIL_STAGE: (state) => {
        state.mailStatus = 'pending'
        state.localMails = JSON.parse(localStorage.getItem('all-mails')) || []
        state.inboxMails = []
        state.sentMails = []
        state.dummy = true
    }
};

const getters = {
    initMailStatus: state => state.mailStatus,
    getInbox: state => state.inboxMails,
    getSent: state => state.sentMails,
};

const actions = {
    MAIL_INIT: ({ commit }, { emailId }) => {
        commit('INIT_MAIL_REQUEST')

        return new Promise((resolve, reject) => {
            try {
                const inboxMails = axios.getInboxMails(emailId)
                const sentMails = axios.getSentMails(emailId)

                Promise.all([inboxMails, sentMails]).then((data) => {
                    commit('INIT_MAIL_SUCCESS', { inboxMails: data[0].data.mails, sentMails: data[1].data.mails });
                    resolve();
                }).catch(() => {
                    reject();
                })

            } catch (error) {
                commit('INIT_MAIL_ERROR')
                reject(error)
            }
        })
    },
    MAIL_DECRYPT: async ({ getters, dispatch }, mails) => {
        mails.forEach(async (mail) => {
            if (!JSON.parse(mail.secrets)[getters.userInfo.emailId]) return;

            const randObj = JSON.parse(mail.secrets)[getters.userInfo.emailId]
            const extPrivateKey = await ledgermail_v2.getXPrivateKey(await ledgermail_v2.getHexFormMnemonic(getters.mnemonic));
            mail.randomString = await ledgermail_v2.decrypt(JSON.parse(randObj), extPrivateKey)

            mail.body = await dispatch('DATA_DECRYPT', { cipherText: mail.body, secret: mail.randomString })
            mail.attachments.forEach(async (attachment) => { attachment.data = await dispatch('DATA_DECRYPT', { cipherText: attachment.data, secret: mail.randomString }) });

            mail.is_decrypt = true;

            mail.parent_mails.forEach(async (rMail) => {
                rMail.body = await dispatch('DATA_DECRYPT', { cipherText: rMail.body, secret: mail.randomString })
                rMail.attachments.forEach(async (attachment) => { attachment.data = await dispatch('DATA_DECRYPT', { cipherText: attachment.data, secret: mail.randomString }) });
            });

            mail.forward_children.forEach(async (fMail) => {
                fMail.body = await dispatch('DATA_DECRYPT', { cipherText: fMail.body, secret: mail.randomString })
                fMail.attachments.forEach(async (attachment) => { attachment.data = await dispatch('DATA_DECRYPT', { cipherText: attachment.data, secret: mail.randomString }) });
            });
        });
    },
    DECRYPT_SINGLE: async ({ getters, dispatch }, mail) => {
        if (mail.is_decrypt) return;
        else if (!JSON.parse(mail.secrets)[getters.userInfo.emailId]) return;

        const randObj = JSON.parse(mail.secrets)[getters.userInfo.emailId]
        const extPrivateKey = await ledgermail_v2.getXPrivateKey(await ledgermail_v2.getHexFormMnemonic(getters.mnemonic));
        mail.randomString = await ledgermail_v2.decrypt(JSON.parse(randObj), extPrivateKey)

        mail.body = await dispatch('DATA_DECRYPT', { cipherText: mail.body, secret: mail.randomString })
        mail.attachments.forEach(async (attachment) => { attachment.data = await dispatch('DATA_DECRYPT', { cipherText: attachment.data, secret: mail.randomString }) });

        mail.is_decrypt = true;

        mail.parent_mails.forEach(async (rMail) => {
            rMail.body = await dispatch('DATA_DECRYPT', { cipherText: rMail.body, secret: mail.randomString })
            rMail.attachments.forEach(async (attachment) => { attachment.data = await dispatch('DATA_DECRYPT', { cipherText: attachment.data, secret: mail.randomString }) });
        });

        mail.forward_children.forEach(async (fMail) => {
            fMail.body = await dispatch('DATA_DECRYPT', { cipherText: fMail.body, secret: mail.randomString })
            fMail.attachments.forEach(async (attachment) => { attachment.data = await dispatch('DATA_DECRYPT', { cipherText: attachment.data, secret: mail.randomString }) });
        });
    },
    COMPOSE_MAIL: async ({ getters }, { sender, receiver, subject, body, attachments, CC, secrets }) => {
        const privateKey = await ledgermail_v2.getPrivateKeyFromPhrase(getters.mnemonic);

        return new Promise((resolve, reject) => {
            coreApi.createMail(privateKey, { sender, receiver, subject, body, attachments, CC, secrets, ParentID: null })
                .then((result) => {
                    if (result.success) resolve(result);
                    else reject(result.message)
                })
                .catch((error) => {
                    console.log('[Compose mail] ERROR: ', error)
                    reject("Error 404: Please check your connection and try again later!")
                })
        });
    },
    REPLY_MAIL: async ({ getters }, { sender, receiver, subject, body, attachments, CC, secrets, parentId }) => {
        const privateKey = await ledgermail_v2.getPrivateKeyFromPhrase(getters.mnemonic);

        return new Promise((resolve, reject) => {
            coreApi.replyMail(privateKey, { sender, receiver, subject, body, attachments, CC, secrets, parentId })
                .then((result) => {
                    if (result.success) resolve(result);
                    else reject(result.message)
                })
                .catch((error) => {
                    console.log('[Reply mail] ERROR: ', error)
                    reject("Error 404: Please check your connection and try again later!")
                })
        })
    },
    FORWARD_MAIL: async ({ getters }, { sender, receiver, subject, body, attachments, CC, secrets, previousAttachments, previousMails }) => {
        const privateKey = await ledgermail_v2.getPrivateKeyFromPhrase(getters.mnemonic);

        return new Promise((resolve, reject) => {
            coreApi.forwardMail(privateKey, { sender, receiver, subject, body, attachments, CC, secrets, previousAttachments, previousMails })
                .then((result) => {
                    if (result.success) resolve(result);
                    else reject(result.message)
                })
                .catch((error) => {
                    console.log('[Forward mail] ERROR: ', error)
                    reject("Error 404: Please check your connection and try again later!")
                })
        })
    },

    DATA_ENCRYPT: ({ commit }, { message, secret }) => {
        commit('DUMMY');
        return new Promise((resolve) => {
            resolve(CryptoJS.AES.encrypt(message, secret).toString());
        })
    },
    DATA_DECRYPT: ({ commit }, { cipherText, secret }) => {
        commit('DUMMY');
        return new Promise((resolve) => {
            var bytes = CryptoJS.AES.decrypt(cipherText, secret);
            resolve(bytes.toString(CryptoJS.enc.Utf8));
        })
    },
};

export default { state, mutations, getters, actions };