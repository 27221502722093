const state = {
    alerts: [],
    snackbar: false,
    snackMessage: '',
    snackType: 'info'
}

const mutations = {
    NEW_ALERT: (state, data) => {
        state.alerts.push(data)
        setTimeout(() => {
            state.alerts.pop();
        }, 5000);
    },
    NEW_SNACK: (state, data) => {
        state.snackbar = true;
        state.snackMessage = data.message;
        state.snackType = data.type;
    },
    SNACKBAR_COMPLETE: (state, value) => {
        state.snackbar = value;
        state.snackMessage = "";
        state.snackType = "info";
    },
    DUMMY: (state, data) => {
        state.dummy = data
    },
};

const getters = {
    getAlerts: state => state.alerts,
    snackbar: state => state.snackbar,
    snackMessage: state => state.snackMessage,
    snackType: state => state.snackType,
};

const actions = {
    SNACKBAR: ({ commit }, message) => {
        commit('SNACKBAR_COMPLETE', false)
        commit('NEW_SNACK', { message, type: 'info' })
    },
    ALERT_SUCCESS: ({ commit }, message) => {
        commit('NEW_ALERT', { message, type: 'success' })
    },
    ALERT_ERROR: ({ commit }, message) => {
        commit('NEW_ALERT', { message, type: 'error' })
    },
    ALERT_WARNING: ({ commit }, message) => {
        commit('NEW_ALERT', { message, type: 'warning' })
    },
};

export default {
    state,
    mutations,
    getters,
    actions,
};